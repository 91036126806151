body, html {
  margin: 0;
}

body {
  color: #ccc;
  background-color: #2b2c2d;
  background-image: url("background.b02bb61f.png");
  background-repeat: repeat;
  font-family: Courier New, Courier, monospace;
}

#app {
  flex-direction: row;
  justify-content: center;
  display: flex;
  position: relative;
}

#loading {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#sidebar {
  box-sizing: border-box;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

#scenario {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#canvas {
  display: block;
}

#tileset {
  display: none;
}

#end {
  width: 100%;
  height: 100%;
  background-image: url("end.fbf1711b.png");
  background-position: center;
  background-repeat: no-repeat;
  display: none;
  position: absolute;
}

.logo {
  width: 100%;
  height: 100%;
  max-height: 241px;
  max-width: 320px;
  text-indent: -2000px;
  background-image: url("logo.5ec87f31.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
}

.canvas-container {
  background-image: url("board-background.e18ec629.png");
  background-repeat: repeat;
  border-radius: 25px;
  margin: 10px;
  padding: 15px;
  position: relative;
  box-shadow: 0 0 10px #00000080;
}

.canvas-container-border {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border: 3px solid #00000080;
  border-radius: 18px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.canvas-inner {
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.canvas-inner-shadow {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 2px 2px 15px #000, inset -2px -2px 15px #000;
}

.sidebar-content {
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0 10px;
}

.list-title {
  color: #749947;
  width: 100%;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}

.stats, .controls {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.stats li {
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 0;
  font-size: 1.125rem;
  font-weight: bold;
  display: flex;
}

.stats-value {
  background-color: #0000004d;
  border-radius: 4px;
  padding: 4px 10px 2px;
  display: block;
}

.stats-value.overflow {
  color: #749947;
  text-transform: uppercase;
}

.buttons {
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  display: flex;
}

.button {
  color: #ccc;
  cursor: pointer;
  background-color: #00000040;
  border: 2px solid #1a1a1a;
  border-radius: 3px;
  padding: 4px 8px 3px;
  font-family: inherit;
  font-size: .9375rem;
}

.button:hover, .button:focus {
  color: #fff;
  background-color: #0006;
  box-shadow: 0 0 5px #ffffff26;
}

.button[disabled] {
  cursor: default;
  opacity: .5;
}

.speed-container {
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  display: flex;
}

.speed-input-container {
  flex-grow: 1;
  padding-left: 10px;
}

.speed-input-container input {
  width: 100%;
}

.footer {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  display: flex;
}

.powered-by {
  text-indent: -2000px;
  width: 100px;
  height: 22px;
  background-image: url("poweredby.b8c09aa5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
  display: block;
}

.links {
  flex-direction: row;
  margin: 0;
  padding: 10px 0 0;
  font-size: .8125rem;
  list-style: none;
  display: flex;
}

.links li:after {
  content: "-";
  padding: 0 10px;
  font-weight: bold;
  display: inline-block;
}

.links li:last-child:after {
  content: "";
}

.links a {
  color: #ccc;
  text-decoration: none;
}

.links a:hover, .links:focus {
  color: #749947;
  text-decoration: underline;
}

.slider {
  accent-color: #749947;
}

/*# sourceMappingURL=index.cc56a7eb.css.map */
